import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Modal from 'react-modal';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { destroyCookie } from 'nookies';
import { Button, Chip } from '@mui/material';

const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';
const STRIPE_PK = process.env.GATSBY_STRIPE_PK || 'pk_test_FjZRqF2ciFEsJzjow8rRdqMW';
const STRIPE_URL = process.env.GATSBY_STRIPE_URL || 'http://localhost:9000/stripe/intent';

const StripeCheckout = ({ paymentIntent, onPaymentSuccess, name }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [checkoutError, setCheckoutError] = useState();
  const [checkoutSuccess, setCheckoutSuccess] = useState();
  const [pending, setPending] = useState(false);

  // Check if the screen is too small to display the card element
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) return null;
  const cardOptions =
    window.innerWidth > 610
      ? {
          style: {
            base: {
              lineHeight: '100px',
              fontWeight: 500,
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSize: '24px',
              '::placeholder': {
                color: '#CFD7E0',
              },
            },
          },
        }
      : null;

  const handlePayment = async (e) => {
    e.preventDefault();
    if (!name.trim() || name.trim().length < 2) {
      alert('Please enter your full name.');
      return;
    }
    setPending(true);
    try {
      const {
        error,
        paymentIntent: { status },
      } = await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name,
          },
        },
      });

      if (error) throw new Error(error.message);

      if (status === 'succeeded') {
        setCheckoutSuccess(true);
        onPaymentSuccess();
        destroyCookie(null, 'paymentIntentId');
      } else {
        alert('Your payment could not be processed. Please make sure your credit card info is correct and try again.');
        setPending(false);
      }
    } catch (err) {
      alert('Your payment could not be processed. Please make sure your credit card info is correct and try again.');
      setPending(false);
      setCheckoutError('Please try again');
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <CardElement className="StripeElement" options={cardOptions} />
      <button
        type="button"
        className="btn"
        onClick={handlePayment}
        disabled={!stripe || pending}
        style={{
          minHeight: 40,
          backgroundColor: '#111d27',
          color: '#fff',
          fontSize: 20,
          borderRadius: 8,
          maxWidth: 750,
          marginTop: 20,
        }}
      >
        {pending ? 'Completing Payment...' : 'Pay Now'}
      </button>
      {checkoutError && (
        <div style={{ marginTop: 60 }}>
          <span style={{ color: 'red' }}>{checkoutError}</span>
        </div>
      )}
    </div>
  );
};

const MyAccount = () => {
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);
  const stripePromise = loadStripe(STRIPE_PK);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [width, setWidth] = useState(200);
  const [paymentIntent, setPaymentIntent] = useState('');
  const [userData, setUserData] = useState();
  const [course, setCourse] = useState();
  const [jwt, setJwt] = useState('');
  const [certificateInfo, setCertificateInfo] = useState({
    fullName: '',
    email: userData?.user.email,
    phone: '',
    licenseType: '',
    licenseState: '',
    licenseNumber: '',
    licenseExpiration: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  });

  useEffect(() => {
    (async () => {
      try {
        let params = new URLSearchParams(window.location.search);
        let jwt = params.get('id');
        if (!jwt) {
          jwt = localStorage.getItem('token');
        }
        setJwt(jwt);
        const jwtData = jwt_decode(jwt);
        localStorage.setItem('token', jwt);
        const userId = jwtData.userId;
        const headers = {
          headers: { 'x-access-token': jwt },
        };

        const userData = await axios.get(`${API_URL}/user/${userId}`, headers);
        setUserData(userData.data);
      } catch (err) {
        localStorage.clear();
        window.location.href = '/signin';
      }
    })();
  }, []);

  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) return null;

  if (!userData) return null;

  const signOut = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const unpaidCourses = () => {
    if (!userData?.unpaidCourses) return null;
    const Unpaid = userData.unpaidCourses.map((course, index) => (
      <Course key={index} course={course} userData={userData} stripePromise={stripePromise} />
    ));

    return Unpaid;
  };

  const certificates = () => {
    if (!userData?.certificates) return null;
    const Certificates = userData.certificates.map((cert, index) => <Certificate key={index} cert={cert} />);
    return Certificates;
  };

  const takeTestLink = userData?.unpaidCourses.length > 0 ? '/FL/Engineering/FLENG51' : '/';
  const coursesText = userData?.unpaidCourses.length > 0 ? 'Pending Payment' : 'My Courses';

  return (
    <Layout fullMenu>
      <article id="main">
        <section className="wrapper style5">
          <h2 style={{ textAlign: 'center' }}>My Account</h2>
          <div className="inner">
            <h4 style={{ color: 'black' }}>Personal Info</h4>
            <p style={{ color: 'black' }}>Email: {userData?.user.email}</p>
            {/* <button>Set Password</button> */}
            <hr style={{ margin: '20px 0px' }} />
            <>
              <h4 style={{ color: 'black', marginBottom: 0 }}>{coursesText}</h4>
              {userData?.unpaidCourses.length == 0 && (
                <a style={{ cursor: 'pointer' }} onClick={() => (window.location.href = takeTestLink)}>
                  Find a Course
                </a>
              )}
            </>
            {unpaidCourses()}
            <hr style={{ margin: '20px 0px' }} />
            {userData?.certificates.length > 0 && <h4 style={{ color: 'black' }}>My Certificates</h4>}
            {certificates()}
          </div>
          <div className="inner" style={{ marginTop: 0 }}>
            <h4>Sign Out</h4>
            <button onClick={signOut}>Sign Out</button>
          </div>
        </section>
      </article>
    </Layout>
  );
};

const Course = ({ course, userData, stripePromise }) => {
  const [showCode, setShowCode] = useState(false);
  const [code, setCode] = useState('');
  const [couponResponse, setCouponResponse] = useState('');
  const [amountDue, setAmountDue] = useState(course.amountDue);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [width, setWidth] = useState(200);
  const [paymentIntent, setPaymentIntent] = useState('');
  const [jwt, setJwt] = useState('');
  const [fullName, setFullName] = useState('');
  const [certificateInfo, setCertificateInfo] = useState({
    fullName: '',
    email: userData?.user.email,
    phone: '',
    licenseType: '',
    licenseState: '',
    licenseNumber: '',
    licenseExpiration: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    searchResults: [],
  });

  const onNameUpdate = async (e) => {
    e.preventDefault();
    const name = e.target.value;
    setFullName(name);
    setCertificateInfo({
      ...certificateInfo,
      fullName: name,
    });

    if (['FLCOS10', 'FLNAIL10', 'FLFACE10', 'FLFULL10'].indexOf(course.code) > -1) {
      if (name.length < 2) return;

      try {
        const res = await axios.post(`${API_URL}/fl/cosmetology/licensee/search`, { search: name });
        setCertificateInfo({
          ...certificateInfo,
          searchResults: res.data,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onLicenseNumberUpdate = (e) => {
    e.preventDefault();
    setCertificateInfo({
      ...certificateInfo,
      licenseNumber: e.target.value,
    });
  };

  const onEmailUpdate = (e) => {
    e.preventDefault();
    setCertificateInfo({
      ...certificateInfo,
      email: e.target.value,
    });
  };

  const onPhoneUpdate = (e) => {
    e.preventDefault();
    setCertificateInfo({
      ...certificateInfo,
      phone: e.target.value,
    });
  };

  const onLicenseTypeUpdate = (e) => {
    e.preventDefault();
    setCertificateInfo({
      ...certificateInfo,
      licenseType: e.target.value,
    });
  };

  const onLicenseExpirationUpdate = (e) => {
    e.preventDefault();
    setCertificateInfo({
      ...certificateInfo,
      licenseExpiration: e.target.value,
    });
  };

  const onAddressUpdate = (e) => {
    e.preventDefault();
    setCertificateInfo({
      ...certificateInfo,
      address: e.target.value,
    });
  };

  const onCityUpdate = (e) => {
    e.preventDefault();
    setCertificateInfo({
      ...certificateInfo,
      city: e.target.value,
    });
  };

  const onStateUpdate = (e) => {
    e.preventDefault();
    setCertificateInfo({
      ...certificateInfo,
      state: e.target.value,
    });
  };

  const onLicenseStateUpdate = (e) => {
    e.preventDefault();
    setCertificateInfo({
      ...certificateInfo,
      licenseState: e.target.value,
    });
  };

  const onZipUpdate = (e) => {
    e.preventDefault();
    setCertificateInfo({
      ...certificateInfo,
      zip: e.target.value,
    });
  };

  const applyCode = async (e) => {
    e.preventDefault();
    try {
      const result = await axios.get(`${API_URL}/discounts/${code}`);
      const coupon = result.data;
      if (coupon.amount.includes('%')) {
        if (coupon.amount == '100%') {
          setAmountDue(0);
          setCouponResponse({ error: false, message: 'Coupon Applied!' });
          setShowCode(false);
          setPaymentIntent('');
          return;
        }
        const percent = parseFloat(coupon.amount.split('%')[0] / 100);
        const due = parseFloat(course.amountDue - percent * course.amountDue);
        const finalAmount = Math.max(due, 0.5);
        setAmountDue(parseFloat(finalAmount).toFixed(2));
        setCouponResponse({ error: false, message: 'Coupon Applied!' });
        setShowCode(false);
      } else {
        const due = parseFloat(course.amountDue - parseFloat(coupon.amount));
        const finalAmount = Math.max(due, 0.5);
        setAmountDue(parseFloat(finalAmount).toFixed(2));
        setCouponResponse({ error: false, message: 'Coupon Applied!' });
        setShowCode(false);
      }
    } catch (err) {
      setCouponResponse({ error: true, message: 'Invalid Code' });
    }
  };

  const surveyPay = async () => {
    openModal();
    if (amountDue == 0) return;
    try {
      const res = await axios.post(STRIPE_URL, {
        currency: 'usd',
        amount: parseFloat(amountDue),
        email: userData.user.email,
        userId: userData.user.id,
        force3dSecure: true,
      });
      setPaymentIntent(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const checkRequiredFields = () => {
    const requiredFields = [
      'fullName',
      'licenseNumber',
      'licenseType',
      'licenseState',
      'licenseExpiration',
      'address',
      'city',
      'state',
      'zip',
      'phone',
    ];
    for (const field of requiredFields) {
      if (!certificateInfo[field]) {
        return false;
      }
    }
    return true;
  };

  const _renderPayment = () => {
    if (checkRequiredFields() == false) {
      return <p style={{ color: 'red' }}>Please fill out all fields to continue.</p>;
    }
    return (
      <Elements stripe={stripePromise}>
        <StripeCheckout paymentIntent={paymentIntent} onPaymentSuccess={onPaymentSuccess} name={fullName} />
      </Elements>
    );
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      width: '90%',
      height: '70%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      color: 'black',
      maxWidth: 1000,
    },
  };

  const onPaymentSuccess = async (e) => {
    if (e) e.preventDefault();
    const userId = userData.user.id;
    const email = userData.user.email;
    let titles = [];
    // If a multiple unpaid courses of the same type are purchased, we want to combine the titles into one string
    for (const uc of userData.unpaidCourses) {
      if (uc.code == course.code) {
        titles.push(uc.title);
      }
    }

    // TODO: Chaining cert titles with '::' is a hack. We should probably just store an array of titles in the database.
    titles = titles.join('::');
    try {
      const certRes = await axios.post(`${API_URL}/certificates`, {
        ...certificateInfo,
        fullName,
        userId,
        titles,
        courseCode: course.code,
        creditHours: course.hoursFulfilled,
      });
      const cert = certRes.data;
      axios.post(`${API_URL}/certificate/pdf`, {
        email,
        certificateId: cert.uuid,
      });
    } catch (err) {
      console.log('Error creating certificate');
      console.log(err);
    }
    try {
      await axios.post(`${API_URL}/payments`, {
        userId,
        stripePaymentIntent: paymentIntent ? paymentIntent.id : '',
        courseCode: course.code,
        paymentAmount: course.amountDue,
        couponCode: code,
        amountCharged: amountDue,
      });
    } catch (err) {
      console.log('error submitting payment record.');
      console.log(err);
    }
    alert('Your payment was successfull!');
    window.open(course.surveyLink, '_blank');
    window.location.href = `/MyAccount?payment=success${course.code}`;
    sendConversion(course.code);

    closeModal();
  };

  const SelectState = () => {
    return (
      <select
        // style={{ marginTop: 30, marginBottom: 30 }}
        onChange={onLicenseStateUpdate}
      >
        <option value="">1. Select State</option>
        <option selected={certificateInfo.licenseState == 'Alabama'} value="Alabama">
          Alabama
        </option>
        <option selected={certificateInfo.licenseState == 'Alaska'} value="Alaska">
          Alaska
        </option>
        <option selected={certificateInfo.licenseState == 'Arizona'} value="Arizona">
          Arizona
        </option>
        <option selected={certificateInfo.licenseState == 'Arkansas'} value="Arkansas">
          Arkansas
        </option>
        <option selected={certificateInfo.licenseState == 'California'} value="California">
          California
        </option>
        <option selected={certificateInfo.licenseState == 'Colorado'} value="Colorado">
          Colorado
        </option>
        <option selected={certificateInfo.licenseState == 'Connecticut'} value="Connecticut">
          Connecticut
        </option>
        <option selected={certificateInfo.licenseState == 'Delaware'} value="Delaware">
          Delaware
        </option>
        <option selected={certificateInfo.licenseState == 'Florida'} value="Florida">
          Florida
        </option>
        <option selected={certificateInfo.licenseState == 'Georgia'} value="Georgia">
          Georgia
        </option>
        <option selected={certificateInfo.licenseState == 'Guam'} value="Guam">
          Guam
        </option>
        <option selected={certificateInfo.licenseState == 'Hawaii'} value="Hawaii">
          Hawaii
        </option>
        <option selected={certificateInfo.licenseState == 'Idaho'} value="Idaho">
          Idaho
        </option>
        <option selected={certificateInfo.licenseState == 'Illinois'} value="Illinois">
          Illinois
        </option>
        <option selected={certificateInfo.licenseState == 'Indiana'} value="Indiana">
          Indiana
        </option>
        <option selected={certificateInfo.licenseState == 'Iowa'} value="Iowa">
          Iowa
        </option>
        <option selected={certificateInfo.licenseState == 'Kansas'} value="Kansas">
          Kansas
        </option>
        <option selected={certificateInfo.licenseState == 'Kentucky'} value="Kentucky">
          Kentucky
        </option>
        <option selected={certificateInfo.licenseState == 'Louisiana'} value="Louisiana">
          Louisiana
        </option>
        <option selected={certificateInfo.licenseState == 'Maine'} value="Maine">
          Maine
        </option>
        <option selected={certificateInfo.licenseState == 'Maryland'} value="Maryland">
          Maryland
        </option>
        <option selected={certificateInfo.licenseState == 'Massachusetts'} value="Massachusetts">
          Massachusetts
        </option>
        <option selected={certificateInfo.licenseState == 'Michigan'} value="Michigan">
          Michigan
        </option>
        <option selected={certificateInfo.licenseState == 'Minnesota'} value="Minnesota">
          Minnesota
        </option>
        <option selected={certificateInfo.licenseState == 'Mississippi'} value="Mississippi">
          Mississippi
        </option>
        <option selected={certificateInfo.licenseState == 'Missouri'} value="Missouri">
          Missouri
        </option>
        <option selected={certificateInfo.licenseState == 'Montana'} value="Montana">
          Montana
        </option>
        <option selected={certificateInfo.licenseState == 'Nebraska'} value="Nebraska">
          Nebraska
        </option>
        <option selected={certificateInfo.licenseState == 'Nevada'} value="Nevada">
          Nevada
        </option>
        <option selected={certificateInfo.licenseState == 'New Hampshire'} value="New Hampshire">
          New Hampshire
        </option>
        <option selected={certificateInfo.licenseState == 'New Jersey'} value="New Jersey">
          New Jersey
        </option>
        <option selected={certificateInfo.licenseState == 'New Mexico'} value="New Mexico">
          New Mexico
        </option>
        <option selected={certificateInfo.licenseState == 'New York'} value="New York">
          New York
        </option>
        <option selected={certificateInfo.licenseState == 'North Carolina'} value="North Carolina">
          North Carolina
        </option>
        <option selected={certificateInfo.licenseState == 'North Dakota'} value="North Dakota">
          North Dakota
        </option>
        <option selected={certificateInfo.licenseState == 'Ohio'} value="Ohio">
          Ohio
        </option>
        <option selected={certificateInfo.licenseState == 'Oklahoma'} value="Oklahoma">
          Oklahoma
        </option>
        <option selected={certificateInfo.licenseState == 'Oregon'} value="Oregon">
          Oregon
        </option>
        <option selected={certificateInfo.licenseState == 'Pennsylvania'} value="Pennsylvania">
          Pennsylvania
        </option>
        <option selected={certificateInfo.licenseState == 'Puerto Rico'} value="Puerto Rico">
          Puerto Rico
        </option>
        <option selected={certificateInfo.licenseState == 'Rhode Island'} value="Rhode Island">
          Rhode Island
        </option>
        <option selected={certificateInfo.licenseState == 'South Carolina'} value="South Carolina">
          South Carolina
        </option>
        <option selected={certificateInfo.licenseState == 'South Dakota'} value="South Dakota">
          South Dakota
        </option>
        <option selected={certificateInfo.licenseState == 'Tennessee'} value="Tennessee">
          Tennessee
        </option>
        <option selected={certificateInfo.licenseState == 'Texas'} value="Texas">
          Texas
        </option>
        <option selected={certificateInfo.licenseState == 'US Virgin Islands'} value="US Virgin Islands">
          US Virgin Islands
        </option>
        <option selected={certificateInfo.licenseState == 'Utah'} value="Utah">
          Utah
        </option>
        <option selected={certificateInfo.licenseState == 'Vermont'} value="Vermont">
          Vermont
        </option>
        <option selected={certificateInfo.licenseState == 'Virginia'} value="Virginia">
          Virginia
        </option>
        <option selected={certificateInfo.licenseState == 'Washington'} value="Washington">
          Washington
        </option>
        <option selected={certificateInfo.licenseState == 'Washington DC'} value="Washington DC">
          Washington DC
        </option>
        <option selected={certificateInfo.licenseState == 'West Virginia'} value="West Virginia">
          West Virginia
        </option>
        <option selected={certificateInfo.licenseState == 'Wisconsin'} value="Wisconsin">
          Wisconsin
        </option>
        <option selected={certificateInfo.licenseState == 'Wyoming'} value="Wyoming">
          Wyoming
        </option>
      </select>
    );
  };

  const LicenseType = () => {
    if (course.code == 'OHCOS3') {
      return (
        <label style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}>
          License Type
          <select onChange={onLicenseTypeUpdate} value={certificateInfo.licenseType}>
            <option value="">1. Select License Type</option>
            <option selected={certificateInfo.licenseType == 'COSA'} value="COSA">
              Advanced Cosmetologist
            </option>
            <option selected={certificateInfo.licenseType == 'ESTA'} value="ESTA">
              Advanced Esthetician
            </option>
            <option selected={certificateInfo.licenseType == 'HAD'} value="HAD">
              Advanced Hair Designer
            </option>
            <option selected={certificateInfo.licenseType == 'MANA'} value="MANA">
              Advanced Manicurist
            </option>
            <option selected={certificateInfo.licenseType == 'NHSA'} value="NHSA">
              Advanced Natural Hair Stylist
            </option>
            <option selected={certificateInfo.licenseType == 'BTQR'} value="BTQR">
              Boutique Services Registrant
            </option>
            <option selected={certificateInfo.licenseType == 'COS'} value="COS">
              Cosmetologist
            </option>
            <option selected={certificateInfo.licenseType == 'COSI'} value="COSI">
              Cosmetologist Instructor
            </option>
            <option selected={certificateInfo.licenseType == 'EST'} value="EST">
              Esthetician
            </option>
            <option selected={certificateInfo.licenseType == 'ESTI'} value="ESTI">
              Esthetician Instructor
            </option>
            <option selected={certificateInfo.licenseType == 'HD'} value="HD">
              Hair Designer
            </option>
            <option selected={certificateInfo.licenseType == 'HDI'} value="HDI">
              Hair Designer Instructor
            </option>
            <option selected={certificateInfo.licenseType == 'MAN'} value="MAN">
              Manicurist
            </option>
            <option selected={certificateInfo.licenseType == 'MANI'} value="MANI">
              Manicurist Instructor
            </option>
            <option selected={certificateInfo.licenseType == 'NHS'} value="NHS">
              Natural Hair Stylist
            </option>
            <option selected={certificateInfo.licenseType == 'NHSI'} value="NHSI">
              Natural Hair Stylist Instructor
            </option>
            <option selected={certificateInfo.licenseType == 'BARB'} value="BARB">
              Barber
            </option>
            <option selected={certificateInfo.licenseType == 'TCHR'} value="TCHR">
              Barber Teacher
            </option>
            <option selected={certificateInfo.licenseType == 'TCHRA'} value="TCHRA">
              Assistant Barber Teacher
            </option>
          </select>
        </label>
      );
    }
    return (
      <label style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}>
        License Type
        <input type="text" name="license" id="license" onChange={onLicenseTypeUpdate} value={certificateInfo.licenseType} />
      </label>
    );
  };

  const takeAnotherTest = (e) => {
    e.preventDefault();
    window.location.href = `${course.testsPage}`;
  };

  return (
    <div key={course.code} style={{ marginTop: 25 }}>
      <h6 style={{ color: 'black', marginBottom: 0 }}>{course.programTitle}</h6>
      <p
        style={{
          color: 'black',
          fontStyle: 'italic',
          fontSize: 12,
          marginBottom: 0,
        }}
      >
        {course.title.split('::').join(',')}
      </p>

      <p style={{ color: 'black', marginBottom: 0 }}>Hours Completed: {course.hoursFulfilled}</p>
      <p style={{ color: 'black', marginBottom: 1 }}>Price: ${parseFloat(amountDue).toFixed(2)}</p>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {!showCode ? (
          <p style={{ color: 'black' }}>
            {couponResponse?.error == false ? (
              <p style={{ color: 'green', marginBottom: 0 }}>Coupon Applied!</p>
            ) : (
              <>
                <a style={{ cursor: 'pointer' }} onClick={() => setShowCode(true)}>
                  Have a Coupon?
                </a>
              </>
            )}
          </p>
        ) : (
          <div>
            <label style={{ color: 'black', width: '100%', marginBottom: 0 }}>
              Coupon Code
              <input type="text" name="coupon" id="coupon" onChange={(e) => setCode(e.target.value)} />
            </label>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <a onClick={applyCode} style={{ marginRight: 15, cursor: 'pointer' }}>
                Apply
              </a>
              <a style={{ cursor: 'pointer' }} onClick={() => setShowCode(false)}>
                Cancel
              </a>
            </div>
            {couponResponse?.error == true && <p style={{ color: 'red', marginBottom: 0 }}>Invalid Code</p>}
          </div>
        )}
      </div>

      <button style={{ width: '100%' }} className="primary" onClick={surveyPay}>
        Pay for Certificate
      </button>
      <p style={{ marginBottom: 50, marginTop: 10, width: '100%' }}>
        <button style={{ width: '100%' }} className="secondary" onClick={takeAnotherTest}>
          Take Another Test
        </button>
      </p>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <h2 style={{ color: 'black', marginBottom: 5 }}>Licensure Info</h2>
        <form
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <label style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}>
            Full Name
            <input style={{ position: 'relative' }} type="text" value={fullName} width="40%" onChange={onNameUpdate} />
            {certificateInfo.searchResults.length > 0 && (
              <div
                style={{
                  position: 'relative',
                  top: 0,
                  left: 0,
                  width: '100%',
                  maxHeight: '200px',
                  overflow: 'auto',
                  backgroundColor: '#fff',
                  border: '1px solid #ccc',
                  borderTop: 'none',
                  zIndex: 10,
                }}
              >
                <p style={{ color: 'black', marginBottom: 0 }}>Select your name from the list below:</p>
                {certificateInfo.searchResults.map((result) => (
                  <Chip
                    variant="outlined"
                    style={{ color: 'black' }}
                    onClick={() => {
                      setFullName(result['Licensee_Name']);
                      setCertificateInfo({
                        ...certificateInfo,
                        fullName: result['Licensee_Name'],
                        searchResults: [],
                        licenseNumber: result['Lic_Number'],
                        // licenseExpiration: result['Lic_Expiration'],
                        // address: result['Add_1'],
                        // zip: result['Zip'],
                      });
                    }}
                    label={`${result['Licensee_Name']} | ${result['Lic_Number']}`}
                  ></Chip>
                ))}
              </div>
            )}
          </label>
          <label style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}>
            License Number
            <input
              type="text"
              name="license_number"
              id="license_number"
              value={certificateInfo.licenseNumber}
              onChange={onLicenseNumberUpdate}
            />
          </label>
          <label style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}>
            Email
            <input type="text" name="email" id="email" value={certificateInfo.email} onChange={onEmailUpdate} />
          </label>
          <LicenseType />
          <label style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}>
            License State
            <SelectState />
          </label>
          <label style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}>
            License Expiration
            <input
              type="date"
              name="expiration"
              id="expiration"
              value={certificateInfo.licenseExpiration}
              onChange={onLicenseExpirationUpdate}
            />
          </label>

          <label style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}>
            Mailing Address
            <input type="text" name="address" id="address" onChange={onAddressUpdate} value={certificateInfo.address} />
          </label>
          <label style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}>
            City
            <input type="text" name="city" id="city" onChange={onCityUpdate} value={certificateInfo.city} />
          </label>
          <label style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}>
            State
            <input type="text" name="state" id="state" onChange={onStateUpdate} value={certificateInfo.state} />
          </label>
          <label style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}>
            Zip Code
            <input type="text" name="zip" id="zip" onChange={onZipUpdate} value={certificateInfo.zip} />
          </label>
          <label style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}>
            Phone Number
            <input type="text" name="phone" id="phone" onChange={onPhoneUpdate} value={certificateInfo.phone} />
          </label>
          {paymentIntent ? (
            <div
              style={{
                border: '2px solid lightgray',
                padding: 10,
                marginTop: 20,
                width: '100%',
              }}
            >
              <h2
                style={{
                  color: 'black',
                  marginTop: 10,
                  marginBottom: 5,
                  width: '100%',
                }}
              >
                Payment Info
              </h2>
              <p style={{ color: 'black', width: '100%' }}>Payment Amount: ${parseFloat(amountDue).toFixed(2)}</p>
              {_renderPayment()}
            </div>
          ) : (
            <div style={{ width: '100%', marginTop: 30 }}>
              {amountDue == 0 && (
                <button style={{ backgroundColor: 'black' }} onClick={onPaymentSuccess}>
                  Get Free Certificate
                </button>
              )}
            </div>
          )}
        </form>
      </Modal>
    </div>
  );
};

const Certificate = ({ cert }) => {
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const sendToEmail = () => {
    try {
      axios.post(`${API_URL}/certificate/pdf`, {
        certificateId: cert.uuid,
        email: cert.email,
      });
      setEmailSent(true);
    } catch (err) {
      setEmailError(true);
    }
  };
  return (
    <div key={cert.uuid} style={{ marginBottom: 30 }}>
      <h6 style={{ color: 'black', marginBottom: 0 }}>{cert.title}</h6>
      <p style={{ color: 'black', marginBottom: 0 }}>
        Credit Hours: {cert.credit_hours}{' '}
        <a style={{ cursor: 'pointer', fontSize: 14 }} onClick={() => (window.location.href = `/certificate?id=${cert.uuid}`)}>
          Preview
        </a>
      </p>
      <p style={{ marginBottom: 0, marginTop: 0, width: '100%' }}>
        <a href={cert.testsPage}>Take Another Test</a>
      </p>
      {emailSent ? (
        <p style={{ color: 'green' }}>Email Sent!</p>
      ) : (
        <a style={{ cursor: 'pointer', marginRight: 10 }} onClick={sendToEmail}>
          Email Me a Copy
        </a>
      )}
      {emailError && <p style={{ color: 'red' }}>Error sending email, please try again or contact us if the issue persists.</p>}
    </div>
  );
};

const sendConversion = async (programCode) => {
  const tagMap = {
    FLENG5: 'AW-11007156832/YRolCMr58oUYEODEz4Ap',
    MSENG4: 'AW-11007156832/eTHoCNLd84UYEODEz4Ap',
    TXENG4: 'AW-11007156832/v8zQCMWv7oUYEODEz4Ap',
  };
  if (window != 'undefined')
    window.gtag('event', 'conversion', {
      send_to: tagMap[programCode],
    });
};

export default MyAccount;
